.containerContact {
  @extend %container;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 50px;
  box-sizing: border-box;

  &__heading {
    @extend %main-title;
    max-width: 100%;
    margin-bottom: 15px;
    text-align: center;
    color: $dark-blue;

    &--blue {
      color: $blue;
      font-weight: bold;
    }

    @media (min-width: $large) {
      margin-bottom: 30px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  &__input {
    // width: 100%;
    // border-radius: 20px;
    // padding: 10px;
    // border : 2px solid $dark-blue ;
    padding: 8px 0px 8px 20px;
    border-radius: 17.5px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: none;
    opacity: 0.52;
    color: #1b314b;
    font-weight: 500;
    font-size: 12px;
    transition: all 0.3s ease-in-out;
    border: 3px solid white;
    flex: 1 1 auto;
    margin: auto;
    width: 100%;
    box-sizing: border-box;
    @media (min-width: $medium) {
      width: 70%;
    }
    &:focus-visible {
      border: 2px solid $blue;
      outline: none;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      border: 2px solid $blue;
      outline: none;
      transition: all 0.3s ease-in-out;
    }
  }

  &__button {
    @extend .button;
    margin: auto;
    width: 100%;
    @media (min-width: $medium) {
      width: 70%;
    }
  }

  &__errorText {
    color: red;
    @media (min-width: $medium) {
        width: 70%;
        margin:auto;
      }
  }
    &__successText {
      color: green;
      @media (min-width: $medium) {
          width: 70%;
          margin: auto;
        }
    }

  &__title {
    @extend %title;
    max-width: 80%;

    color: $dark-blue;

    &--blue {
      color: $blue;
      font-weight: bold;
    }

    margin-top: 15px;

    @media (min-width: $large) {
      max-width: 76%;
      margin-top: 25px;
    }
  }

  &__paragraph {
    @extend %paragraph;

    font-weight: 500;
    max-width: 95%;
    opacity: 0.7;
    color: $mid-gray;
    padding-top: 12px;

    @media (min-width: $large) {
      max-width: 86%;
      padding-top: 20px;
    }
  }

  &__list {
    padding-left: 30px;
    list-style-type: lower-alpha;
  }

  &__list-title {
    @extend %sub-title;

    font-weight: 500;
    max-width: 95%;
    opacity: 0.8;
    color: $blue;
    padding-top: 10px;

    @media (min-width: $large) {
      max-width: 86%;
      padding-top: 15px;
    }
  }

  &__list-item {
    @extend %paragraph;

    font-weight: 500;
    max-width: 95%;
    opacity: 0.7;
    color: $mid-gray;
    padding-top: 5px;

    @media (min-width: $large) {
      max-width: 86%;
      padding-top: 10px;
    }
  }
}
