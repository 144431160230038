// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.button {
    border-radius: 17.5px;
    background: $blue;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 19px;
    border: 3px solid $blue;
    height: 35px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
        background-color: white;
        color: $blue;
        transition: all 0.3s ease-in-out;
    }
    @media (min-width: $medium) {
        font-size: 16px;
        height: 40px;
        border-radius: 20px;
        padding: 8px 22px;
    }
}
