// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@font-face {
    font-family: "SF Pro Display";
    src: url("../../assets/fonts/SFProDisplay-SemiboldItalic.woff2")
            format("woff2"),
        url("../../assets/fonts/SFProDisplay-SemiboldItalic.woff")
            format("woff");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Display";
    src: url("../../assets/fonts/SFProDisplay-Medium.woff2") format("woff2"),
        url("../../assets/fonts/SFProDisplay-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Display";
    src: url("../../assets/fonts/SFProDisplay-Regular.woff2") format("woff2"),
        url("../../assets/fonts/SFProDisplay-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Display";
    src: url("../../assets/fonts/SFProDisplay-ThinItalic.woff2") format("woff2"),
        url("../../assets/fonts/SFProDisplay-ThinItalic.woff") format("woff");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Display";
    src: url("../../assets/fonts/SFProDisplay-LightItalic.woff2")
            format("woff2"),
        url("../../assets/fonts/SFProDisplay-LightItalic.woff") format("woff");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Display";
    src: url("../../assets/fonts/SFProDisplay-HeavyItalic.woff2")
            format("woff2"),
        url("../../assets/fonts/SFProDisplay-HeavyItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Display";
    src: url("../../assets/fonts/SFProDisplay-UltralightItalic.woff2")
            format("woff2"),
        url("../../assets/fonts/SFProDisplay-UltralightItalic.woff")
            format("woff");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}
