// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.header {
    @extend %container;
    padding-top: 20px;
    padding-bottom: 20px;
    @media (min-width: $medium) {
        padding-top: 14px;
        padding-bottom: 14px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    @media (min-width: $large) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        @media (min-width: $medium) {
            width: 198px;
            height: 68px;
        }
    }

    &__icon {
        cursor: pointer;
        @media (min-width: $large) {
            display: none;
        }
    }

    &__list {
        display: none;
        @media (min-width: $large) {
            display: flex;
            column-gap: 40px;
        }
        &--opened {
            display: flex;
            flex-direction: column;
            padding-top: 43px;
            padding-bottom: 75px;
            @media (min-width: $large) {
                display: none;
            }
        }
    }

    &__item {
        @media (min-width: $large) {
           
        }
        &--opened {
            border-bottom: 1px solid $mid-gray;
            padding-bottom: 21px;
            &:first-child {
                margin-bottom: 21px;
            }
        }
    }

    &__link {
        @extend %paragraph;

        text-decoration: none;
        color: $dark-blue;
        opacity: 0.6;
        &--active {
            opacity: 1;
            font-weight: bold;
        }
    }
}
