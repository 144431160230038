%container {
    max-width: 100%;
    padding-left: 31px;
    padding-right: 31px;
    margin: 0 auto;
    @media (min-width: $medium) {
        max-width: 100%;
        padding-right: 48px;
        padding-left: 48px;
    }
    @media (min-width: $large) {
        max-width: 1120px;
        padding-right: 0;
        padding-left: 0;
    }
}

%main-title {
    font-size: 32px;
    line-height: normal;
    @media (min-width: $medium) {
        font-size: 48px;
    }
}

%title {
    font-size: 28px;
    line-height: normal;
    @media (min-width: $medium) {
        font-size: 32px;
    }
}

%sub-title {
    font-size: 18px;
    @media (min-width: $medium) {
        font-size: 24px;
    }
}

%paragraph {
    font-size: 14px;
    line-height: normal;
    @media (min-width: $medium) {
        font-size: 18px;
    }
}

%link {
    font-size: 11px;
    @media (min-width: $medium) {
        font-size: 14px;
    }
}
