.contact {
  @extend %container;
  display: flex;
  flex-direction: column;
  padding-top: 82px;
  margin-bottom: 100.5px;

  @media (min-width: $medium) and (max-width: $large) {
    padding-top: 120px;
    margin-bottom: 120px;

    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: $large) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    padding-top: 282px;
    margin-bottom: 99.5px;
  }

  &__title {
    @extend %title;
    color: $dark-blue;
    font-weight: 500;
    text-align: center;
    padding-bottom: 40px;
    span {
      font-weight: bold;
      color: $blue;
    }

    @media (min-width: $large) {
      padding-bottom: 0;
      text-align: start;
      margin-right: 86px;
    }
  }

  &__form {
    display: flex;
    align-items: center;
    @media (min-width: $large) {
      margin-left: 86px;
    }
  }

  &__input {
    padding: 8px 0px 8px 20px;
    border-radius: 17.5px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: none;
    opacity: 0.52;
    color: $dark-blue;
    font-weight: 500;
    font-size: 12px;
    margin-right: 8px;
    transition: all 0.3s ease-in-out;
    border: 3px solid white;
    flex: 1 1 auto;
    &:focus-visible {
      border: 3px solid $blue;
      outline: none;
      transition: all 0.3s ease-in-out;
    }

    @media (min-width: $medium) {
      font-size: 16px;
      margin-right: 20px;
    }
  }

  &__button {
    @extend .button;
    margin-bottom: auto;
    margin-top: 0px;
    @media (min-width: $large) {
           margin-top:8px;
        }
  }

  &__buttonNoMargin {
      @extend .button;
  }

  &__inputColumn {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
  }
 
  &__errorText {
    color: red;
    padding-top: 8px;
    padding-left:8px;
  }

  &__successText {
    color : green;
    padding-top: 8px;
    padding-left: 8px;
  }
}
