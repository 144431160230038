.container {
    @extend %container;

    padding-top: 30px;
    padding-bottom: 50px;

    &__heading {
        @extend %main-title;
        max-width: 80%;
        margin-bottom: 15px;
        color: $dark-blue;

        &--blue {
            color: $blue;
            font-weight: bold;
        }

        @media (min-width: $large) {
            max-width: 56%;
            margin-bottom: 30px;
        }
    }

    &__title {
        @extend %title;
        max-width: 80%;

        color: $dark-blue;

        &--blue {
            color: $blue;
            font-weight: bold;
        }
        margin-top: 15px;

        @media (min-width: $large) {
            max-width: 56%;
            margin-top: 25px;
        }
    }

    &__paragraph {
        @extend %paragraph;

        font-weight: 500;
        max-width: 95%;
        opacity: 0.7;
        color: $mid-gray;
        padding-top: 12px;

        @media (min-width: $large) {
            max-width: 86%;
            padding-top: 20px;
        }
    }
    &__list {
        padding-left: 30px;
        list-style-type: lower-alpha;
    }
    &__list-title {
        @extend %sub-title;

        font-weight: 500;
        max-width: 95%;
        opacity: 0.8;
        color: $blue;
        padding-top: 10px;

        @media (min-width: $large) {
            max-width: 86%;
            padding-top: 15px;
        }
    }
    &__list-item {
        @extend %paragraph;

        font-weight: 500;
        max-width: 95%;
        opacity: 0.7;
        color: $mid-gray;
        padding-top: 5px;

        @media (min-width: $large) {
            max-width: 86%;
            padding-top: 10px;
        }
    }
}
