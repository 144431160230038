// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.footer {
  @extend %container;
  padding-bottom: 10px;
  border-top: 1px solid $mid-gray;
  box-sizing: border-box;
  width:100%;
    
  @media (min-width: $medium) {
    padding-bottom: 10px;
  }

  @media (min-width: $large) {
    padding-bottom: 10px;
  }

  &__content {
    padding-top: 18.5px;
    
    // display: flex;
    // grid-template-columns: repeat(12, minmax(0, 1fr));
    // justify-content: space-between;

    @media (min-width: $small) {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    @media (min-width: $medium) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__logo {
    padding-bottom: 40px;
    grid-column: span 12 / span 12;
    transition: all 0.3s ease-in-out;

    @media (min-width: $medium) {
      padding-bottom: 0;
      grid-column: span 3 / span 3;
      width: 156px;
      height: 54px;
    }

    @media (min-width: $large) {
      width: 198px;
      height: 68px;
    }
  }
}
