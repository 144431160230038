.list {
    padding-bottom: 24px;
    grid-column: span 6 / span 6;

    @media (min-width: $medium) {
        grid-column: span 2 / span 2;
    }

    &--icons {
        @media (min-width: $medium) {
            // margin-left: auto;
            grid-column: span 3 / span 3;
        }
    }

    &__title {
        color: $dark-blue;
        font-weight: 500;
        padding-bottom: 12px;
        @media (min-width: $large) {
            font-size: 24px;
            padding-bottom: 18px;
        }
    }

    &__item {
        &:not(:last-child) {
            padding-bottom: 12px;
        }
    }

    &__link {
        font-size: 12px;
        font-weight: 500;
        color: $mid-gray;
        text-decoration: none;
        transition: color 0.2s ease-in-out;
        &:hover {
            color: $blue;
            transition: color 0.2s ease-in-out;
        }
        @media (min-width: $large) {
            font-size: 18px;
        }
    }

    &__icon {
        display: inline;
        &:not(:last-child) {
            padding-right: 12.3px;
        }
        img {
            transition: all 0.3s ease-in-out;
            &:hover {
                filter: brightness(0.5);
                transition: all 0.3s ease-in-out;
            }
            @media (min-width: $large) {
                width: 30.19px;
                height: 30.01px;
            }
        }
    }
}
