.hero-about {
    @extend %container;

    padding-top: 70px;
    padding-bottom: 80px;

    @media (min-width: $medium) {
        padding-top: 88px;
        padding-bottom: 120px;
    }

    @media (min-width: $large) {
        padding-top: 112px;
        padding-bottom: 227px;
    }

    &__content {
        text-align: center;
        @media (min-width: $large) {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    }

    &__title {
        @extend %title;
        max-width: 80%;
        margin: 0 auto;
        color: $dark-blue;
        padding-bottom: 40px;
        &--blue {
            color: $blue;
            font-weight: bold;
        }

        @media (min-width: $large) {
            max-width: 56%;
        }
    }

    &__main-title {
        @extend %main-title;
        max-width: 80%;
        margin: 0 auto;
        color: $dark-blue;
        &--blue {
            color: $blue;
            font-weight: bold;
        }

        @media (min-width: $large) {
            max-width: 50%;
        }
    }

    &__paragraph {
        @extend %paragraph;
        font-weight: 500;
        max-width: 95%;
        opacity: 0.7;
        color: $mid-gray;
        padding-top: 18px;
        margin: 0 auto;
        @media (min-width: $large) {
            max-width: 66%;
            padding-top: 40px;
        }
    }

    &__devices {
        position: relative;
        padding-top: 164px;
        width: 453px;
        height: 280px;

        @media (min-width: $medium) {
            padding-top: 175px;
            width: 802px;
            height: 427px;
        }

        @media (min-width: $large) {
            width: fit-content;
            height: 427px;
            padding-top: 0;
        }

        img {
            position: absolute;

            @media (min-width: $medium) {
                height: 100%;
                width: 802px;
                height: 427px;
                right: -16%;
            }

            @media (min-width: $large) {
                position: static;
                width: 100%;
            }
        }
    }
}
