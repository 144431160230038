.workflow {
    @extend %container;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__main-title {
        @extend %title;
        font-weight: 500;
        color: $dark-blue;
        padding-top: 12.9px;
        padding-bottom: 12.9px;
        text-align: center;

        @media (min-width: $medium) {
            padding-top: 40px;
            padding-bottom: 18px;
        }
    }

    &__paragraph {
        @extend %paragraph;
        color: $mid-gray;
        text-align: center;

        @media (min-width: $medium) {
            max-width: 73%;
            margin: 0 auto;
        }

        @media (min-width: $large) {
            max-width: 44%;
        }
    }

    &__timeline {
        margin: 50px auto 0 auto;

        @media (min-width: $medium) {
            margin: 100px auto 0 auto;
            padding-left: 80px;
            padding-right: 80px;
        }
    }

    &__card {
        position: relative;

        &:nth-child(odd) {
            padding: 30px 0 30px 30px;

            &::before {
                left: 0px;
                top: -4.5px;
                bottom: -4.5px;
                border-width: 5px 0 5px 5px;
                border-radius: 50px 0 0 50px;
            }

            .workflow__title::before {
                left: -77px;
                @media (min-width: $medium) {
                    left: -89px;
                }
            }
        }

        &:nth-child(even) {
            padding: 30px 30px 30px 0;

            &::before {
                top: 0;
                right: 0;
                bottom: -1px;
                border-width: 5px 5px 5px 0;
                border-radius: 0 50px 50px 0;
                @media (min-width: 380px) and (max-width: 450px) {
                    top: -1px;
                    bottom: 0;
                }
                @media (min-width: $medium) {
                    bottom: -1px;
                    top: 0;
                }
            }
            .workflow__title-image-container {
                justify-content: flex-end;
            }

            .workflow__title::before {
                right: -58px;
                @media (min-width: $medium) {
                    right: -70px;
                }
            }
        }

        &::before {
            content: "";
            position: absolute;
            width: 50%;
            border: solid $blue;
        }

        &:first-child {
            &::before {
                border-top: 0;
                border-top-left-radius: 0;
            }
        }

        &:last-child:nth-child(odd)::before {
            border-bottom: 0;
            border-bottom-left-radius: 0;
        }

        &:last-child:nth-child(even)::before {
            border-bottom: 0;
            border-bottom-right-radius: 0;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        background: #ffff;
        padding: 13px 20px 15px 20px;
        border-radius: 20px;
        border: 2px solid $blue;
        @media (min-width: $medium) {
            padding: 20px 32px 30px 32px;
            border-radius: 30px;
        }
    }

    &__title {
        color: $blue;
        position: relative;
        font-weight: 500;
        @extend %paragraph;
        padding-left: 4px;

        &::before {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            background: white;
            border-radius: 999px;
            border: 3px solid $blue;
        }

        @media (min-width: $medium) {
            padding-bottom: 12px;
            padding-top: 12px;
        }
    }

    &__card-paragraph {
        @extend %link;
        color: $dark-blue;
        font-weight: 500;
        line-height: normal;
        padding-top: 8px;
        @media (min-width: $medium) {
            padding-top: 0;
        }
    }

    &__card-image {
        width: 20px;
        height: 20px;
        object-fit: contain;
    }
}
