// Colors

$off-white: #f9f9f9;
$light-gray: #bbc5d5;
$mid-gray: #7f93b2;
$blue: #4981da;
$dark-blue: #1b314b;
$pink: #da4949;
$light-sky: #e4def0;
$gradient-dark-blue: linear-gradient(#4981da 0%, #c9acff 100%);
$gradient-purple: linear-gradient(rgba(218, 73, 73, 0.25) 0%, #6c40be 100%);
$gradient-blue: linear-gradient(#4981da 0%, #eee5ff 100%);

// Breakpoints
$small: 375px;
$medium: 768px;
$large: 1280px;
