/**
 * Basic typography style for copy text
 */
html {
    overflow-x: hidden;
}
body {
    font-family: "SF Pro Display";
    overflow-x: hidden;
    background-color: $off-white;
}
