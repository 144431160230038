.features {
    @extend %container;

    padding-bottom: 100px;

    @media (min-width: $medium) {
        padding-bottom: 109px;
        padding-right: 0;
    }

    @media (min-width: $large) {
        padding-bottom: 120px;
    }

    &__word {
        @extend %paragraph;
        display: block;
        text-align: center;
        color: $blue;
        font-weight: 500;
    }

    &__title {
        @extend %title;
        font-weight: 500;
        text-align: center;
        padding-top: 12px;
        padding-bottom: 24.7px;
        color: $dark-blue;
        @media (min-width: $medium) {
            padding-bottom: 54px;
        }
        @media (min-width: $large) {
            padding-bottom: 98px;
        }
    }

    &__content {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        @media (min-width: $medium) {
            justify-content: start;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__services {
        order: 2;
        @media (min-width: $medium) {
            order: 0;
            margin-right: 46px;
        }
        @media (min-width: $large) {
            margin-right: 177px;
        }
    }

    &__service {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        &:not(:last-child) {
            padding-bottom: 40px;
        }

        @media (min-width: $medium) {
            flex-direction: row;
            justify-content: start;
            align-items: flex-start;
            text-align: start;
            padding-bottom: 24px;
        }
        @media (min-width: $large) {
            padding-bottom: 40px;
        }
    }

    &__icon {
        img {
            width: 47px;
            height: 47px;
            @media (min-width: $large) {
                height: 63px;
                width: 63px;
            }
        }

        @media (min-width: $medium) {
            padding-right: 12px;
        }

        @media (min-width: $large) {
            padding-right: 32px;
        }
    }

    &__service-title {
        @extend %sub-title;
        color: $dark-blue;
        font-weight: bold;
        padding-top: 12px;
        padding-bottom: 12px;
        @media (min-width: $large) {
            padding-bottom: 18px;
            font-weight: 500;
        }
    }

    &__service-paragraph {
        @extend %paragraph;
        color: $mid-gray;
        margin: 0 auto;
        max-width: 65%;
        @media (min-width: $medium) {
            max-width: 100%;
            margin: 0;
        }
    }

    &__blob {
        position: relative;
        margin-bottom: 22px;
        right: -52px;

        img {
            max-width: 100%;
            max-height: 100%;

            @media (min-width: $medium) {
                object-fit: cover;
                object-position: left;
                height: 100%;
            }
        }
        @media (min-width: $medium) {
            margin-left: 0;
        }

        @media (min-width: $large) {
        }
    }
}

#features {
    scroll-margin: 24px;
}
